.Motto{
  text-align: center;
  vertical-align: center;
  font-family: cursive;
  margin-top: 100px;
}

.SignIn{
  margin-top: 7px;
  border-color: #26a69a;
  border-radius: 5px;
  border-width: 3px;
  border-style: solid;
}

.FieldError{
  border-bottom-color: red !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
}

.HoverUnderline:hover{
  text-decoration: underline;
}

.row{
  display:inherit;
}

.LoginScreenLogo
{
    position: absolute;
    width: 80px;
    height: 80px;
    margin-left: -120px;
    margin-top:-20px;
}

.login-input{
  background-color: #FAFAFA;
  border-radius: 10px !important;
}

.login-input:focus{
  box-shadow: #F18B81 1px 1px 1px 1px !important;
  border: #F18B81 1px solid !important;
}

.column-wrapper{
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: scroll;
  height:100vh;
  max-height:100vh;
}

.column-wrapper::-webkit-scrollbar{
  display: none;
}

.login-column{
  position: relative; 
  display: flex;
  padding-top:32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 32px;
}


.login-label{
  font-size: small;
  margin-top: 16px;
}

.icon-right{
  position:absolute;
  top: 0;
  right: 16px;
}

.log-in-button{
  width:100%;
  border-radius: 20px;
  text-align: center;
  background: linear-gradient(to right, var(--center-color-darker), var(--center-color), var(--center-color-lighter), var(--center-color), var(--center-color-darker));
  padding: 0.85rem 1.1rem;
  margin-top:16px;
  cursor: pointer;
  font-weight: bold;
  text-transform: none;
}

.log-in-button:disabled{
  opacity: 40%;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5); /* IE */
  -moz-transform: scale(1.5); /* FF */
  -webkit-transform: scale(1.5); /* Safari and Chrome */
  -o-transform: scale(1.5); /* Opera */
  transform: scale(1.5);
  padding: 10px;
  accent-color: #FFBB8C;
  margin-right: 8px;
  margin-left: 5px;
}

.log-in-button-2{
  background: linear-gradient(to right, var(--center-color-2-darker), var(--center-color-2), var(--center-color-2-lighter), var(--center-color-2), var(--center-color-2-darker));
}

.field-error{
  display: flex;
  align-items: center;
  background: #F0DDE2;
  width: fit-content;
  padding: 10px;
  margin-top: 16px;
  border-radius: 10px;
}

.field-success{
  background: #4C7E83;
  color: white;
}

.fa-exclamation-triangle{
  margin-right: 5px;
}

::-webkit-datetime-edit{
  color:#a9a9a9
}

select::disabled{ 
  color: #a9a9a9; 
}

.input-error{
  background-color: rgba(244,67,54,.05)!important;;
  border: 1px solid var(--cirrus-danger)!important;
  color: var(--cirrus-danger);
  padding: 5px;
  max-width: 500px;
  margin-top: 16px;
}

.pc-img{
  position:fixed;
  z-index:-1;
  width:50vw;
  height:100vh;
  right:0; 
  display:none;
}

.tbl-img{
  position:fixed;
  z-index:-1;
  width:100vw;
  height:30vh;
  top:0; 
  display: none;
}

.phone-img{
  position:fixed;
  z-index:-1;
  width:100vw;
  height:30vh;
  top:0; 
  display: none;
}

.outer-column{
  width: 58.33333%;
}

.skyreachers-title{
  font-size: 40px;
  margin-bottom: 0px;
}

.skyreachers-title-line{
  font-family:sans-serif;
  font-size:20px;
  font-weight:550;
  display:inline-flex;
}

@media screen and (min-width:1024px) {
  .pc-img{
    display:block;
  }
}

@media  screen and (min-width: 768px) and (max-width:1023px) {
  .tbl-img{
    display:block;
  }

  .column-wrapper{
    height: 75vh;
    max-height: 75vh;
    margin-top: 25vh;
  }

  .outer-column{
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .phone-img{
    display:block
  }

  .column-wrapper{
    height: 75vh;
    max-height: 75vh;
    margin-top: 25vh;
  }

  .outer-column{
    width: 90%;
    margin-left: 5%;
  }

  .skyreachers-title{
    font-size: 30px;
  }

  .skyreachers-title-line{
    font-size:14px;
  }

  .LoginScreenLogo
  {
    width: 60px;
    height: 60px;
    margin-left: -68px;
    margin-top:-22px;
  }

  .login-title{
    margin-left: 50px;
  }

  p{
    margin: 0 !important;
  }

  .tbl-img{
    display: block;
  }
}

@media screen and (max-width:350px){
  .LoginScreenLogo
  {
    width: 50px;
    height: 50px;
    margin-left: -68px;
    margin-top:-18px;
  }

  .skyreachers-title{
    font-size: 20px;
  }

  .skyreachers-title-line{
    font-size:10px;
  }
}



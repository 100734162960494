.edgebutton {
    width: 40px;
    height: 40px;
    display: block;
    background: #eee;
    border: 1px solid rgb(0, 0, 0);
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
  }

.react-flow__edge-path{
    cursor: pointer;
    display: inline-block;     
    margin: -5%;
    padding: 5%;
    stroke-width: 2px !important;
  }

  .thin-path
  {
    stroke-width: 2px !important;
  }

  .react-flow__edge.selected .react-flow__edge-path.thin-path{
    stroke: rgb(60, 0, 255) !important;
  }

  .thick-path
  {
    stroke-width: 40px !important;
    stroke: none !important;
  }

.react-flow__edge-path:focus{
    box-shadow: 0 0 3px blue !important
  }
  
  .edgebutton:hover {
    box-shadow: 0 0 0 1px blue !important;
  }

  .edgebutton:focus {
    box-shadow: 0 0 0 1px blue !important;
  }
  
  .edgebutton-foreignobject {
    background: transparent;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
  }
ul .divider::after {
    color: #000000;
}

ul .divider {
    border-top: 0.1rem solid #000000
}

.sidebar{
    background-color: #FCFCFC;
    width: 368px;
    height:100%;
    position:fixed;
    right: -368px;
    margin-right: 0;
    transition:transform 1s;
    z-index: 2001;
    border-top-left-radius: 20px;
    pointer-events: all;
}

.profile-pic-wrapper{
    display: flex;
    transform: scale(2.5);
    justify-content: center;
    margin-top: 64px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

.switch{
    display: flex;
    margin:0 !important;
}

.sidebar-icon{
    color: #f18b81;
    margin-right: 8px;
}

.sidebar-link{
    font-size: 1.5rem;
    margin: 24px;
    pointer-events: all;
    margin-left: 48px;
}


.open-menu{
    -webkit-transform: translate(-368px);
    -ms-transform: translate(-368px);
    transform: translate(-368px);
  }


ul .divider::after{
    font-size: 0.9rem
}

.frame-footer{
    display: flex;
    justify-content: center;
}

.logoutButton{
    margin: 0;
    color: black;
    font-size: 1rem;
}

.close-button{
    position: fixed;
    right: 12px;
    top: 4px;
    font-weight: bolder;
    font-size: 26px;
    cursor: pointer;
    z-index: 2002;
}

.sidebar-wrapping{
    position:fixed;
    z-index:2000;
    width:100%;
    height:100%;
    margin-top:-64px;
    pointer-events:none;
}

.black-backing{ 
    position:fixed;
    z-index:2000;
    width:100%;
    height:100%;
    background-color:black;
    opacity:0.4;
    pointer-events: all;
}

@media screen and (min-width: 768px) {
    
    ul .divider::after{
        font-size: 1.2rem
    }

    .logoutButton{
        font-size: 1rem;
    }

    input:checked + .sidebar {
        -webkit-transform: translate(20vw);
        -ms-transform: translate(20vw);
        transform: translate(20vw);
      }

}

@media screen and (max-width: 1023px) {
    .sidebar-wrapping{
        margin-top:0;
    }
}

@media screen and (max-width: 767px) {
    .sidebar{
        width:100%;
        right: -100%;
    }

    .open-menu{
        -webkit-transform: translate(-100%);
        -ms-transform: translate(-100%);
        transform: translate(-100%);
      }
}


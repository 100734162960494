.Parent-Div {
    width: 100%;
    background-color: white;
    border-radius: 40px;
    border: #F18B81 1px solid;
  }
  
.Child-Div {
    height: 100%;
    border-radius: 40px;
    text-align: right;
  }

.milestone-list-container {
    background-color: #F5F0F7;
    padding: 4%;
    border-radius: 20px;
    width: 90%;
    border: 1px solid #E6DAEB;
    margin-top: 32px;
}

.milestone-list-title {
    font-size: large;
    text-align: center;
}

.milestone-list-divider {
    height: 1px;
    width: 110%;
    margin-left: -5%;
    background: #E6DAEB;
}

.milestone-list-item{
    display:flex;
    justify-content:space-between;
    align-items:center;
    text-align: center;
    margin-bottom: 10px;
}

@media screen and (max-width: 1023px){
    .milestone-list-item{
        flex-direction: column;
    }
} 

.small-screen-list{
    display: none;
}

.milestone-list-title-mobile{
    margin-left: 16px;
    margin-top: 16px;
}

.milestone-item-container{
    width: 250px;
    background-color: #F5F0F7;;
    padding: 8%;
    border: 1px solid #E6DAEB;
    height: 80px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.milestone-list-wrapper{
    overflow-x: scroll;
    margin-left:16px;
}

.milestone-description{
    cursor: pointer;
    width:55%;
    line-height: 1;
}


.milestone-list-wrapper::-webkit-scrollbar{
    display: none;
}

@media screen and (max-width: 1023px) {
    .milestone-description{
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .large-screen-list{
        display: none
    }

    .small-screen-list{
        display:block
    }
}

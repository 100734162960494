.reactive-text-short{
    font-size: 1.2rem !important;
}

.reactive-text-medium{
    font-size: 1.0rem !important;
}

.reactive-text-long{
    font-size: 0.5rem !important;
}

a{
    color:black
}

a:hover{
    color:black
}
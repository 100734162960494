.user-greeting-container{
    display:flex;
    align-items:center;
    justify-content:flex-start;
}

.greeting-wrapper{
    /* Use -60 when user Level is visible
    margin-left: -60px;*/
    margin-left:-110px;
    margin-top: 15px;
    line-height:0 !important;
}

.greeting-intro{
    margin-bottom:-10px;
}

.greeting-name{
    font-size:large;
    margin:0
}

@media screen and (max-width: 1023px) {
    .greeting-wrapper{
        margin-top: 5px;
    }
}

@media screen and (max-width: 767px) {
    .greeting-wrapper{
        margin-top: -5px;
    }
}
.player-wrapper {
    /* padding-top: 56.25%  Player ratio: 100 / (1280 / 720) */
  }
  
.react-player {
  display: block;
}

#postText {
  border: none;
  background: none;
  width: 100%;
  margin: 0;
}

.readmoreGradient{
  height:60%;
  background: linear-gradient(to bottom, transparent, white);
}

.readmore{
  position: absolute;
  right: 15px;
  font-size: small;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  background: #FAFAFA;
  padding-left: 5px;
  padding-right:2px;
}

.readless {
  position: relative;
  background-image: none;
  width:100%;
  cursor: pointer;
}

.post-body{
  overflow-y: hidden;
  border-radius: 10px;
  background-color: #fafafa;
  border: 1px solid #e4e4e4;
  margin-bottom: 12px;
  margin-top:12px;
}

.post-right{
  margin-left: 16px;
}

.post-left{
  margin-right: 16px;
}

@media screen and (max-width:768px) {
  .post-right{
    margin-left: 0;
  }
  
  .post-left{
    margin-right: 0;
  }
}

.tier-wrapper{
    display:flex;
    align-items:center;
    flex-direction: column;
    width: 100vw;
    height:100%;
    justify-content: center;
}

.tier-container{
    display: flex;
    height: 500px;
    overflow-x: scroll;
    max-width: 100vw;
}

.tier-container::-webkit-scrollbar{
    display:none;
}

.tier{
    width: 320px;
    padding: 10px;
    border: 1px solid black;
    margin-left:10px;
    margin-right:10px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    box-shadow: black 1px 1px 0px 0px;
}

.tier-list{
    height: 200px;
}

.tier-button{
        margin-bottom:10px;
        height: 48px;
        background: linear-gradient(to right, var(--center-color-darker), var(--center-color), var(--center-color-lighter), var(--center-color), var(--center-color-darker));
        border-radius: 20px; 
        text-transform: none;
        border: var(--center-color) 1px solid
}

.promo-code{
    display: flex;
    margin-top:12px;
    align-items: center;
}

.promo-code-input{
    background-color: #FAFAFA;
    border-radius: 10px !important;
  }
  
.promo-code-input:focus{
    box-shadow: #F18B81 1px 1px 1px 1px !important;
    border: #F18B81 1px solid !important;
  }

.promo-code-button{
    margin-top: 10px;
    background: linear-gradient(to right, var(--center-color-2-darker), var(--center-color-2), var(--center-color-2-lighter), var(--center-color-2), var(--center-color-2-darker));
  }

.tier-li:hover{
    background-color: unset !important;
}

.tier-li{
    font-weight: 600;
}

.tier-unready{
    opacity: 50%;
    font-style: italic;
    font-weight:400;
}

.stripe-container{
    width:95%;
    border: 1px solid black;
    box-shadow:black 1px 1px 0 0;
    padding:10px; 
    border-radius:20px;
    max-height: 80vh;
    overflow: scroll;
}

.stripe-container::-webkit-scrollbar{
    display: none;
}
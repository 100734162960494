@keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #f87b07; 
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    position:fixed;
    z-index: 9999;
    left:-25px;
    top:-25px;
    margin-left: 50vw;
    margin-top: 50vh;
  }

  .spinner-container{
    background-color:rgb(0,0,0,0.5);
    position:fixed;
    left: 0px;
    top: 0px;
    height:100vh;
    width:100vw;
    z-index: 9998;
  }
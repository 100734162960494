switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 25%;
    left: 33%;
    right: 33%;
    bottom: 25%;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    width:33%;
    height:50%;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 80%;
    width: 30%;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(200%);
    -ms-transform: translateX(200%);
    transform: translateX(200%);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .suggested-goal-btn{
    background-color:#F5F0F7;
    padding: 20px;
    width: 95%;
    border-radius:20px;
    cursor: pointer;
  }

  .SMART{
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    margin: 10px;
    border: 1px solid lightgray;
    box-shadow: 1px 1px 1px 1px black;
  }
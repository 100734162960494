.toggle-button{
    width: 95px;
    padding: 5px;
    background: #FAFAFA;
    border: 1px solid #E4E4E4;
    color: #676767;
    cursor:pointer;
}

.toggle-button-wrapper{
    width:100%;
    margin-top:32px;
    margin-bottom:32px;
    text-align:center;
}

.log-in-toggle{
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.sign-up-toggle{
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: none;
}

.active-toggle-button{
    font-weight: bold;
    background: linear-gradient(to right, var(--center-color-2-darker), var(--center-color-2), var(--center-color-2-lighter), var(--center-color-2), var(--center-color-2-darker));
}

@media screen and (max-width:767px){
    .toggle-button-wrapper{
        width:100%;
        margin-top:16px;
        margin-bottom:16px;
        text-align:center;
    } 
}

@media screen and (max-width:350px){
    .toggle-button{
        width:65px;
        font-size:small;
    } 
}
.fileSelection{
    width: 80%;
    padding: 10%;
    margin-top: 5% !important;
    margin-left: 10% !important;
    border: gray 1px dashed !important;
    background: white !important;
}
.checkbox {
    scale: 0.8;
}
.aboutMeText{
    max-width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
}

span.aboutMe{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.75rem;
}

.header-button{
    margin-top:1%;
    margin-bottom: 1%;
    margin-right:-1%;
    position:absolute;
    top: 0;
    right:50px;
    color: white !important;
    background: #f18b81 !important;
    border:1px solid white !important;
    border-radius: 20px;
}

.profile-icon{
    font-size: x-large;
}

@media screen and (max-width: 768px){
    .header-button{
        top: 50px;
        right:10px;
        font-size: small;
    }

    .profile-icon{
        font-size: large;
    }
}


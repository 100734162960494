.react-flow__node-beginningNode{
    /* background: #D6D5E6; */
    background: #fdae77;
    border: 1px solid #222138;
    border-radius: 100%;
    height:150px;
    width: 150px;
    display: flex;
    align-items:  center;
    justify-content: center;
    text-align:center;
    font-weight: bold;
}

.react-flow__node-goalNode{
    background: #79b2b6;
    border: 1px solid #222138;
    border-radius: 100%;
    height:150px;
    width: 150px;
    display: flex;
    align-items:  center;
    justify-content: center;
    text-align:center;
    font-weight: bold;
}

.react-flow__node-milestoneNode{
    /* background: #D6D5E6; */
    background: #b8cbcd;
    border: 1px solid #222138;
    border-radius: 100%;
    height:150px;
    width: 150px;
    display: flex;
    align-items:  center;
    justify-content: center;
    text-align:center;
}

.react-flow__node-milestoneNode:hover, .react-flow__node-milestoneNodeWithPost:hover{
    box-shadow: 0 0 5px rgb(123, 199, 226);
}

.react-flow__node-milestoneNodeWithPost {
    /* background: #D6D5E6; */
    background: #feda88;
    border: 1px solid #222138;
    border-radius: 100%;
    height:150px;
    width: 150px;
    display: flex;
    align-items:  center;
    justify-content: center;
    text-align:center;
}
  
#milestonePostLabel{
    border: none;
    text-align: center;
    font-size: 2.5rem;
    letter-spacing: 0.025rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    line-height: 1.2;
 }

 #nodeLabel{
    max-width: 75px;
    line-height: normal;
    display:inline-block;
    vertical-align: middle;
    font-size: 12px;
    font-weight: bold;
    overflow-wrap: break-word;
    color: #333;

 }
.customHeader{
  background: linear-gradient(to right, #E3C9DC, #F8C4BC, #FBD4BD, #FBD4BD, #FEE2C4,  #FBD4BD, #FBD4BD, #F8C4BC, #E3C9DC );
  height: 64px;
  margin-bottom: auto;
  border-bottom: 1px white solid;
  z-index: 1001;
  padding:0;
}

.headerText{
  color:White;
  cursor: default;
  margin: 0;
}

.header-text-div{
  display: none;
}

.headerTitle{
  color:White;
  cursor: default;
  margin-left: 2% !important;
  font-size: 1.7rem;
}

.customHeaderBrand{
  height: 100%; 
  justify-content: space-between; 
  width:100%;
  overflow: none;
}

.notification-count{
  background: #940b6c;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  position: absolute;
  top: 15%;
  right: 0;
}

.nav-item{
  padding: 0 !important
}

.header .nav-item a {
  padding: 0 !important
}

.explore-bar{
    width: 100%;
    height: 32px;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: #676767 1px solid !important;
    box-shadow: none !important;
}

.pc-menu{
  display:flex;
  margin-left:32px;
}

.tablet-menu{
  display:none;
}

.menu-icon{
  font-size: x-large;
  margin-right:4px;
}

.menu-text{
  font-size: large;
}

.last-menu-element{
  margin-right: 64px !important;
}

.tablet-link-div{
  margin-left: 32px;
}

@media screen and (max-width: 1023px) {
  .tablet-link-div{
    display:flex;
    flex-direction: column;
    margin-top: 10px; 
  }

  .tablet-menu{
    display:flex;
    margin-left: 32px !important;
  }

  .menu-text{
    font-size: medium;
  }

  .pc-menu{
    display:none;
  }


  .tablet-link{
    display:contents !important;
    margin-left:32px;
  }

  .menu-icon{
    font-size: large;
    margin-right: 0;
  }

  .customHeader{
    top:unset !important;
    bottom: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
}

.first-menu-element{
  margin-left: 64px !important;
}

@media screen and (max-width: 767px) {

  .last-menu-element{
    margin-right: 16px !important;
  }

  .menu-text{
    font-size: small;
  }

  .menu-icon{
    font-size: medium;
    margin-right: 0;
  }

  .tablet-link{
    margin-left:16px !important;
  }

  .tablet-link-div{
    margin-left:16px !important;
  }
}
.custom-footer{
    height: 86px;
    background-color: #F5F0F7;
    position:fixed;
    bottom:0;
    width: 100%;
}

.content-line{
    display:flex;
    justify-content:space-around;
    align-items:center;
    padding-left:350px;
    padding-right:350px;
}

.copyright-line{
    font-size: small;
}

@media screen and (max-width: 1023px) {
    .custom-footer{
        position: relative;
    }
    
    .content-line{
        padding-left:220px;
        padding-right:220px;
    }
}

@media screen and (max-width: 767px) {
    .content-line{
        padding-left:10px;
        padding-right:10px;
        font-size: small;
    }

    .copyright-line{
        font-size: x-small;
    }
}



.notification-count{
  background: #940b6c;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  position: relative;
  top: -25px;
  left: 15px;
}

.notification-banner{
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
  position:absolute;
  top:0;
}

.edit-button{
  display: none;
}

.notif-button{
  font-size:xx-large;
  margin-top:32px;
}

.page-header{
  position: absolute;
  left: 16px;
  font-size: x-large;
  font-weight: bold;
  margin-top: 32px;
}

.header-profile{
  margin-top: 24px;
  margin-left: 12px;
}

@media screen and (min-width: 1024px){
  .notification-banner{
    display: none
  }
}

@media screen and (max-width: 767px){
  .notification-count{
    background: #940b6c;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    position: relative;
    top: -22px;
    left: 12px;
  }

  .edit-button{
    display: block;
    font-size:x-large;
    margin-top:22px;
    margin-right:12px;
  }

  .notif-button{
    font-size: x-large;
  }
}
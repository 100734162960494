.textInputButton{
    margin-left:10px;
    margin-bottom:10px;
    height: 48px;
    background: linear-gradient(to right, var(--center-color-darker), var(--center-color), var(--center-color-lighter), var(--center-color), var(--center-color-darker));
    border-radius: 20px; 
    text-transform: none;
    border: var(--center-color) 1px solid;
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.text-bubble{
  width: 60%;
  margin: 5px !important;
  padding: 5px;
  background: #E6DAEB;
  border-radius: 10px;
  position:relative;
  flex-shrink: 0;
  margin-left: 20px;
}

.skye-chat{
  width:1000px;
  height:600px;
  border-radius: 20px;
  margin-top:12px; 
  background: #fafafa;
  border: 1px solid #E4E4E4
}

.message-box{
  width: 1000px;
  height: 480px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.skye-title{
  margin-top: 24px;
  margin-left: 21px;
  width:fit-content;
  background: #f5f0f7;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  border: 1px solid black;
  font-weight: bolder;
  font-size: medium;
}

.message-box::-webkit-scrollbar{
  display: none;
}

.message-container{
  display:flex;
  position: relative;
  width:1000px;
  flex-shrink: 0;
  padding: 10px;
}

.skye-message{
  justify-content: flex-end;
}

.user-message{
  background-color: #ffd8bc;
}

.skye-right{
  display: none;
}

.skye-left{
  display: none;
}

.blur-block{
    height: 5px;
    margin-top: -13px;
    z-index: 200;
    position: absolute;
    backdrop-filter: blur(1px);
}

.message-profile-icon{
  width:40px;
  border-radius: 100%;
}

.loading-message{
  width: 60px !important;
  padding-left:10px;
}

.text-loading {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 20px;
}

.skye-alert{
  width: fit-content;
  padding: 10px;
  margin-top: 12px;
  border-radius: 10px;
  margin-bottom: -12px;
  display: flex;
  align-items: center;
}

.skye-error{
    background: #F0DDE2;
}

.skye-listening{
  position: relative;
  background: #E4E4E4;
  padding-left:40px;
}

.skye-thinking{
  background: #ffd8bc;
  padding-top:15px;
  padding-bottom: 15px;
}

.dot {
  width: 6px;
  height: 6px;
  margin-left:4px;
  background-color: #333;
  border-radius: 50%;
  opacity: 0.4;
  animation: loading 2s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

.ring-container {
  position: relative;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 20px;
}

.ringring {
  border: 3px solid #62bd19;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: 15px;
  top: 15px;
  animation: pulsate 1s ease-out;
  -webkit-animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; 
  opacity: 0.0
}

@keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}

@media screen and (max-width:767px) {
  .skye-chat{
    height: 520px;
  }

  .message-box{
    height: 400px;
  }

  .message-container{
    font-size: small;
  }
}

@media screen and (min-width: 1024px){
  .skye-title-wrapper{
    display: flex;
    justify-content: center;
    width:100%;
  }

  .skye-title{
    margin-left:0;
  }
}

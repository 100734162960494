.container {
    position: relative;
}

.selectJourney {
    margin-bottom: "0";
}

ul {
    list-style-type: none;
    display:block;
}

li:hover {
    background-color: #D6D5E6;
}

/* Define a CSS variable for the central color */
.options {
    display: none;
    position: absolute;
    top: 50px;
    left: 25px;
    z-index: 100;
    background-color: white;
    margin: 0;
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    max-height: 12rem;
    width: 220px;
    justify-content: center;
    text-align: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius:20px;
    border: var(--center-color) 3px solid;
}


.options::-webkit-scrollbar {
    display:none;
  }
  

.show {
    display: block;
}

.journey-card{
    position: relative;
    height: 55vh;
    border-radius: 0;
    margin-bottom: 0 !important;
    box-shadow: none !important;
}

.journey-footer{
    width: 90%;
    display: block;
    margin-left:5%;
}

.react-flow__edge.inactive{
    pointer-events: all !important;
}

.not-found-card{
    display: flex;
    height: 30vh;
    align-items: center;
    justify-content: center;
}

/* removes tooltip arrow */
.rc-tooltip-arrow {
    display: none !important;
}

.unsaved-changes-div{
    position:absolute;
    top:2%;
    display:flex; 
    width:100%;
    justify-content:center;
    z-Index:1200;
}

.unsaved-changes-font{
    font-size: 0.8rem
}

.journey-title{
    margin-top: 24px;
    margin-left: 21px;
    width:fit-content;
    background: #f5f0f7;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
    border: 1px solid black;
    font-weight: bolder;
    font-size: medium;
    display: none;
}


@media screen and (max-width: 768px) {
    .unsaved-changes-font{
        font-size: 0.6rem
    }    
}

.journey-details:disabled{
    border: lightgray 1px solid !important;
    color: black !important;
    background:white !important;
    cursor:default !important;
}

.row{
    padding: 0 !important;
}


.qc-icon{
    position:absolute;
    top:0;
    font-size: small !important;
}

.toast{
    position: absolute;
    z-index: 1100;
}

.select-black-background{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color:black;
    opacity: 0.4;
    top: 0;
    left: 0;
    z-index:1010 !important; 
}

.card__action-bar{
    width: 100%;
}

.AI-button{
    color:  #794585 !important;
    border: #794585 1px solid !important;
    font-size: large;
}

.btn-info{
    color:  white !important;
    background-color: #F18b81 !important;
    border: #794585 1px solid !important;
    font-weight: bold;
}

.AI-button:hover{
    color:  white !important;
    background-color: #794585 !important;
}

@media screen and (max-width: 1023px) {
    .journey-wrapper{
        margin-top: 12px;
    }

    .AI-button{
        font-size: x-small;
    }

    .journey-title{
        display: block;
    }

    .card__action-bar{
        font-size: small;
    }
}
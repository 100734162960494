.time-settings{
    margin-top: -32px;
}

@media screen and (max-width:767px){
    .time-settings{
        margin-top: 0 
    }
}

::-webkit-datetime-edit{
    color:black !important
  }

.save-button{
    margin-left:10px;
    margin-bottom:10px;
    height: 48px;
    border-radius: 20px; 
    text-transform: none;
    border: var(--center-color-2) 1px solid;
    background: linear-gradient(to right, var(--center-color-2-darker), var(--center-color-2), var(--center-color-2-lighter), var(--center-color-2), var(--center-color-2-darker));
    margin-right:10px;
}
.journey-link-container{
    width: 250px;
    background-color: #F9F1F3;
    padding: 15px;
    align-items: flex-start;
    border: 1px solid #F0DDE2;
    height: 170px;
    border-radius: 5px;
}

.journey-link {
    color: #000000;
    text-decoration: none;
    display: block;
    text-align: center;
}

.journey-link:hover {
    color:#000000; 
    text-decoration:none; 
    cursor:pointer;  
}

.milestone-date{
    width: 35%;
}

@media screen and (max-width: 1023px) {
    .journey-link-container{
        width: 180px;
    }

    .milestone-description{
        width: 100%;
    }

    .milestone-date{
        width: 100%;
    }
}


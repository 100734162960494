.postInput{
    margin-left:10px;
    margin-bottom:10px;
    height: 48px;
    background: linear-gradient(to right, var(--center-color-darker), var(--center-color), var(--center-color-lighter), var(--center-color), var(--center-color-darker));
    border-radius: 20px; 
    text-transform: none;
    border: var(--center-color) 1px solid
}

.publish{
    background: linear-gradient(to right, var(--center-color-2-darker), var(--center-color-2), var(--center-color-2-lighter), var(--center-color-2), var(--center-color-2-darker));
    margin-right:10px;
}

.new-post{
    width: 100%;
    overflow: initial;
    background: #fafafa;
    border: 1px solid #E4E4E4;
    padding: 10px;
    border-radius: 10px;
    margin-top: 16px;  
}

.postbox-title{
    width:100%;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 15px;
    border-bottom: 1px solid #E4E4E4;
}

.post-content:focus {
    border:none;
    box-shadow: none;
  }

.post-content{
    border: none;
    background: none;
    width: 100%;
    margin: 0;
}

.post-container{
    max-height: 300px;
    overflow: scroll;
}

.post-container::-webkit-scrollbar {
    display:none;
  }

.journey-dropdown-div{
    min-height:3rem;
    width:250px;
}

.media-div{
    margin-left: 24px;
    width: 150px;
}

.new-post-error{
    display: flex;
    justify-content: space-between;
    text-align: center;
    background: #e26D61;
    padding: 5px;
    padding-top: 15px;
    border-radius: 5px;
    font-size: 0.8rem;
}

.new-post-info{
    display: flex;
    justify-content: space-between;
    text-align: center;
    background: #ffd8bc;
    padding: 5px;
    padding-top: 15px;
    border-radius: 5px;
    font-size: 0.8rem;
}

@media screen and (max-width: 767px) {
    .journey-dropdown-div{
        width:90%;
    }

    .media-div{
        width: 60%;
        margin-left:0;
    }
}
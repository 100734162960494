:root {
    --center-color: hsl(25, 100%, 87%); 
    --center-color-lighter: hsl(25, 100%, 90%);
    --center-color-darker: hsl(25, 100%, 84%);
    --center-color-2: hsl(6, 69%, 73%);
    --center-color-2-lighter: hsl(6, 69%, 76%);
    --center-color-2-darker: hsl(6, 69%, 70%)
  }

.card{
    border-radius: 20px;
    padding: 2px;
}

button.btn-transparent:hover{
    --btn-color: none
}

button.btn-transparent:focus{
    box-shadow: none;
}

@media screen and (device-platform: "ios"){
    body{
        padding-top:"20px";
        padding-top:constant(safe-area-inset-top);
        padding-top:env(safe-area-inset-top);
    }
}

.centralDiv{
    width:96%;
    margin-left:2%;
    margin-top: 2%;
    position:absolute;
    padding-bottom: 20px;
}

.content-column{
    overflow-y: scroll;
    overflow-x: hidden;
}

.avatar::before{
    color: inherit !important;
}

.pc-only{
    display: none !important;
}

.phone-only{
    display: inherit !important;
}

@media screen and (min-width: 768px) {
    .centralDiv {
       width: 68%;
       margin-left: 16%;
    }

    .phone-only{
        display: none !important;
    }
}

@media screen and (min-width: 1024px) {
    .header-filler{
        margin-top: 64px;
    }

    .content-column{
        padding-bottom:"86px";
        overflow-y: unset;
    }

    .pc-only{
        display: inherit !important;
    }
}

@media screen and (max-width: 1023px) {
    .page-content-top-padding{
        padding-top:  96px !important;
    }
}

.newsfeed-category-button{
    padding: 5px;
    padding-right: 10px;
    border-radius: 20px;
    font-weight: bold;
    margin-bottom: 0;
    margin-right:16px;
    text-transform: none;
}

.newsfeed-category-button-active{
    background-color: #F5F0F7;
}

.horizontal-scroll{
    width:100%;
    overflow-x:scroll;
}

.horizontal-scroll::-webkit-scrollbar{
    display: none;
}

.card{
    background: #fafafa;
    border: 1px solid #E4E4E4;
}

.spotlight-post{
    background-color: #F5F0F7;
    border: #F5F0F7 1px solid;
    border-radius: 20px;
    height: 360px;
}

.notif-card{
    width:60%;
    margin-top:16px;
}

@media screen and (max-width:767px) {
    .contact-font{
        font-size: small;
    }
}

@media screen and (max-width:1023px) {
    .card-internal-header{
        display:none;
    }

    .spotlight-post{
        display:none;
    }

    .notif-card{
        width:95%;
        margin-top:72px;
    }
}



.to-do-list-container {
    background-color: #FFF5EE;
    padding: 4%;
    border-radius: 20px;
    width: 90%;
    border: 1px solid #FFD8BC;
    margin-top: -32px;
}

.to-do-list-title {
    font-size: large;
}

.to-do-list-divider {
    height: 1px;
    width: 110%;
    margin-left: -5%;
    background: #FFD8BC;
}

.home-mobile-title-wrapper{
    margin-left: 16px;
    margin-top: -32px;
}

.to-do-item-container{
    width: 250px;
    background-color: #FFF5EE;
    padding: 16px;
    align-items: flex-start;
    border: 1px solid #FFD8BC;
    height: 80px;
    border-radius: 5px;
    font-size: small;
}

.to-do-list-wrapper{
    overflow-x: scroll;
    margin-left: 16px;
}

.to-do-list-wrapper::-webkit-scrollbar{
    display: none;
}

.small-screen-list{
    display: none;
}

@media screen and (max-width: 767px) {
    .large-screen-list{
        display: none
    }

    .small-screen-list{
        display:block
    }
}